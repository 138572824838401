<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.schedule') }}</h1>

        <FilterEvents @filter-change="filerChange"></FilterEvents>

        <v-layout align-center
                  justify-space-between
                  class="mb-8">
            <v-flex shrink class="pr-5">
                <v-switch
                        v-model="calendarMode"
                        inset
                        input-value="true"
                        @change="changeMode()">
                        <template v-slot:label>
                            <span v-if="calendarMode">
                                {{ $t('labels.show_list') }}
                            </span>
                            <span v-else>
                                {{ $t('labels.show_calendar') }}
                            </span>
                        </template>
                </v-switch>
            </v-flex>
            <!-- <v-flex >
                <a class="mr-3"
                   @click="showImport = true">
                    <v-icon>{{ ICONS.IMPORT }}</v-icon>
                    {{ $t('configurations.imports.import_events') }}
                </a>
            </v-flex> -->

            <v-layout justify-end>
                <v-btn color="secondary"
                    v-if="this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.INSPECTION_SCHEDULE)"
                    @click="addNewEvent()">New
                </v-btn>
            </v-layout>
        </v-layout>

        <Calendar v-if="calendarMode"
                  @date-click="showEventForm"
                  @drop="dropEvent"
                  ref="calendar"
                  @event-click="showDetails">
        </Calendar>
        <List v-if="!calendarMode"
            :pageNumber="pageNumber"
            @show-event-click="showDetails"
            @edit-event-click="editEvent"
            @remove-event-click="removeEvent"
            @change-page="changePage"
            @sort="sortEvents" >
        </List>
        <ManageEvent v-if="manageEventFormVisibility"
            :chosen-date="chosenDate"
            @fetch="fetchEvents"></ManageEvent>
        <EventDetails v-if="editedEventId"
                      @fetch="fetchEvents"
                      @close="closeDetails"></EventDetails>

        <!-- <ImportEventsModal :dialog="showImport"
                              @close="showImport = false"
                              @visibility="showImport = $event"></ImportEventsModal> -->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { GET_STRING_DATE, IS_CURRENT_DATE_LESS_OR_EQUAL } from '@/helpers/dates';
import Calendar from '@/components/scheduling/Calendar';
import List from '@/components/scheduling/List';
import FilterEvents from '@/components/scheduling/FilterEvents';
import ManageEvent from '@/components/scheduling/manageEvent/ManageEvent';
import EventDetails from '@/components/scheduling/EventDetails';
import { DateTime } from 'luxon';

import { ICONS } from '@/constants/icons';
// import ImportEventsModal from '@/components/scheduling/import/ImportEventsModal';

export default {
    name: 'Scheduling',
    components: { Calendar, List, ManageEvent, EventDetails, FilterEvents }, // ImportEventsModal
    data: () => ({
        filter: {},
        pageNumber: 1,
        sortingColumn: null,
        chosenDate: '',
        calendarMode: true,
        delta: null,
        showImport: false,
        ICONS
    }),
    computed: {
        ...mapState('scheduling', [
            'manageEventFormVisibility',
            'editedEventId',
            'eventData.date'
        ])
    },
    methods: {
        changeMode () {
            this.pageNumber = 1;
            this.fetchEvents();
        },
        changePage (page) {
            this.pageNumber = page;
            this.fetchEvents();
        },
        sortEvents (sortingColumn) {
            this.sortingColumn = sortingColumn;
            this.fetchEvents();
        },
        dropEvent (e) {
            if (this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INSPECTION_SCHEDULE)) {
                this.delta = e.delta;
                const id = e.event._def.publicId;
                this.$store.dispatch('scheduling/getActiveEvent', id)
                    .then(() => {
                        this.$store.commit('scheduling/SET_EDIT_EVENT_ID', id);
                        this.date = this.deltaDate(this.date, this.delta);
                    }).then(() => {
                        this.$store.dispatch('scheduling/updateEvent').finally(() => {
                            this.$store.dispatch('scheduling/clearActiveEventData').finally(() => {
                                this.delta = null;
                                this.fetchEvents();
                                this.$refs.calendar.refreshCalendar();
                            });
                        });
                    });
            }
        },
        deltaDate (d, delta) {
            if (d) {
                const dt = DateTime.fromISO(d);
                return dt.plus({ year: delta.years, months: delta.months, days: delta.days }).toISODate();
            }
        },
        filerChange (newFilter) {
            // console.log('FILTER', newFilter);
            this.filter = newFilter;
            this.pageNumber = 1;
            this.fetchEvents();
        },
        fetchEvents () {
            const params = {
                ...this.filter,
                'page': (this.calendarMode ? 1 : this.pageNumber),
                'page_size': (this.calendarMode ? 10000 : 20)
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            return this.$store.dispatch('scheduling/getEvents', params);
        },
        canEdit () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.INSPECTION_SCHEDULE);
        },
        addNewEvent () {
            const today = new Date();

            this.showEventForm({ date: today, dateStr: GET_STRING_DATE(today) });
        },
        showEventForm (e) {
            if (IS_CURRENT_DATE_LESS_OR_EQUAL(e.date) && this.canEdit()) {
                this.chosenDate = e.dateStr;
                this.$store.commit('scheduling/SET_MANAGE_EVENT_FORM_VISIBILITY', true);
            }
        },
        editEvent (id) {
            this.$store.dispatch('scheduling/getActiveEvent', id)
                .then(() => {
                    this.$store.commit('scheduling/SET_EDIT_EVENT_ID', id);
                    this.$store.commit('scheduling/SET_MANAGE_EVENT_FORM_VISIBILITY', true);
                });
        },
        removeEvent (id) {
            return this.$store.dispatch('scheduling/deleteEvent', id)
                .then(() => {
                    this.fetchEvents();
                });
        },
        showDetails (e) {
            if (e) {
                this.$store.dispatch('scheduling/getActiveEvent', e)
                    .then(() => {
                        this.$store.commit('scheduling/SET_EDIT_EVENT_ID', e);
                        this.$store.commit('scheduling/SET_EVENT_DETAILS_VISIBILITY', true);
                    });
            }
        },
        closeDetails () {
            this.$store.commit('scheduling/SET_EVENT_DETAILS_VISIBILITY', false);
            this.$store.dispatch('scheduling/clearActiveEventData');
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.INSPECTION_SCHEDULE)) {
                vm.fetchEvents();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>

<style scoped>

</style>
