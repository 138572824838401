<template>
    <div>
        <FullCalendar :options="calendarOptions"
                      ref="calendar"></FullCalendar>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';

export default {
    name: 'Calendar',
    components: { FullCalendar },
    data: () => ({
        lastStartDate: '',
        lastEndDate: ''
    }),
    computed: {
        ...mapState('locale', [
            'currentLocale'
        ]),
        ...mapState('scheduling', [
            'events'
        ]),
        calendarOptions () {
            return {
                plugins: [dayGridPlugin, interactionPlugin, rrulePlugin],
                initialView: 'dayGridMonth',
                locale: this.currentLocale,
                events: this.prepareCalendarEvents(),
                headerToolbar: {
                    right: 'prev,next'
                },
                editable: false,
                selectMirror: true,
                selectable: true,
                dateClick: this.handleDateClick,
                datesSet: this.handleDatesSet,
                eventClick: this.handleEventClick,
                eventDrop: this.handleDrop
            };
        },
        calendarApi () {
            return this.$refs.calendar.getApi();
        }
    },
    methods: {
        prepareCalendarEvents () {
            if (this.lastStartDate && this.lastEndDate) {
                return this.events;
            } else {
                return [];
            }
        },
        refreshCalendar () {
            this.calendarApi.destroy();
            this.$nextTick(() => {
                // console.log('refreshCalendar');
                this.calendarApi.render();
            });
        },
        handleDrop (event) {
            this.$emit('drop', event);
        },
        handleDateClick (event) {
            this.$emit('date-click', event);
        },
        handleDatesSet (event) {
            if (this.lastStartDate !== event.startStr && this.lastEndDate !== event.endStr) {
                this.$emit('set-dates', {
                    start: event.startStr,
                    end: event.endStr
                });

                this.lastStartDate = event.startStr;
                this.lastEndDate = event.endStr;
            }
        },
        handleEventClick (event) {
            this.$emit('event-click', event.event._def.publicId);
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    @import "@/assets/css/_mixins.scss";

    ::v-deep {
        & .fc-toolbar-title {
            font-weight: 500;
        }
        & .fc-today-button {
            height: 36px;
            min-width: 64px;
            padding: 0 16px;
            background-color: $secondary !important;
            border-color: $secondary !important;
            color: #fff;
            border-radius: 4px;
            font-weight: 500;
            &:disabled {
                background-color: rgba(0, 0, 0, 0.12) !important;
                border-color: transparent !important;
                color: rgba(0, 0, 0, 0.26) !important;
                opacity: 1;
                cursor: default;
            }
        }
        & .fc-button-primary.fc-prev-button {
            @include fullcalendar_navigation_buttons;
            border-radius: 4px 0 0 4px;
        }
        & .fc-button-primary.fc-next-button {
            @include fullcalendar_navigation_buttons;
            border-radius: 0 4px 4px 0;
        }
        & .fc-col-header-cell {
            font-weight: 500;
        }
        & .fc-daygrid-day.fc-day-today {
            background-color: $light_yellow;
        }
        & .fc-daygrid {
            width: 100%;
        }
        & .fc-daygrid-event-dot {
            border-color: $secondary;
        }
        & .fc-daygrid-dot-event .fc-event-title {
            font-weight: 500;
        }
        & .fc-h-event {
            background-color: $secondary;
            border-color: $secondary;
            & .fc-event-time {
                font-weight: 500;
            }
        }
    }
</style>
